.loader-container {
  position: absolute;
  top: 0;
  left:0;
  z-index: 1199;
  height: 100%;
  width: 100%;
  background-color: rgba(255,255,255,0.3);
  display: flex;
  overflow-x: hidden;
  overflow-y: hidden;
}

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
}